<template>
  <div class="assessBox">
    <div class="app-container">
      <div class="titlebox">
        <el-button size="medium"
                   type="text"
                   @click="$router.go(-1)">返回</el-button>
        <div class="title">{{Viewform.userName}}-评估详情</div>
      </div>
      <div class="viewbox">
        <div class="level_box">
          <!-- <div class="level_box_levelbox">
            <span class="level_box_now">评估职级：{{rankName==0?'副总':rankName==1?'总监':rankName==2?'经理':rankName==3?'主管':'员工'}}级</span>
            <span v-show="isAssess"
                  class="level_box_old">原有职级：{{originRankName||"暂无评"}}级</span>
          </div> -->
          <div class="level_box_btnbox"
               v-show="isAssess">
            <el-button size="small"
                       class="level_box_btnbox_audit"
                       type="primary"
                       @click="handleGoAudit">评估审核</el-button>
            <!-- <el-button size="small"
                       class="level_box_btnbox_export"
                       type="primary"
                       @click="handleExport"
                       v-show="importBtnVisible">导出</el-button> -->
          </div>
        </div>
        <el-form>
          <div class="qualityevaluation">
            <div v-for="(question ,index) in questions"
                 :key="index">
              <div class="title">{{question.className}}</div>
              <el-form-item v-for="(indicator ) in question.indicatorList"
                            :key="indicator.indicatorId "
                            :rules="[{  required: true}]"
                            :label="indicator.indicatorName">
                <div class="assessRadio">
                  <el-radio-group v-model="answerList[indicator.indicatorId].selectedOptionId"
                                  class="radioArr">
                    <el-radio v-for="(option ,optionIndex) in indicator.optionList"
                              :key="optionIndex"
                              :label="option.optionId">
                      <span style="color:#000;padding:0 5px;">{{option.content}}</span>
                    </el-radio>
                  </el-radio-group>
                </div>
                <el-form-item label="描述依据"
                              :rules="[{required: true }]"
                              class="fwn">
                  <div>
                    <el-input style="margin-top:-12px;"
                              type="textarea"
                              :autosize="{ minRows: 3, maxRows: 4}"
                              maxlength="200"
                              show-word-limit
                              v-model="answerList[indicator.indicatorId].remark"></el-input>
                  </div>
                </el-form-item>
              </el-form-item>
              <el-divider></el-divider>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from '@/api/peopelPostMatch';
export default {
  data () {
    return {
      importBtnVisible: true,
      assessmentType: 0,

      originRankName: '',
      rankName: 1,
      rankLevel: 1,
      rankNote: '',
      talentType: '',
      competencyNote: '',
      synthesizeAppraise: '',
      appointment: '',
      appointmentNote: '',
      isAssess: true,
      userName: '',
      evaluateUsername: '',
      evaluateTime: '',
      auditor: '',
      auditTime: '',
      currentItem: {},
      questions: [],
      answerList: [],
      Viewform: {
        id: '',
        userName: '',
        comId: ''
      }
    };
  },
  methods: {
    handleExport () {
      const params = {
        id: this.$route.query.id
      };

      const url = `${document.location.origin}/v1/talent/exportUserReport?params=${encodeURIComponent(JSON.stringify(params))}`;

      window.open(url);
    },
    getInfo () {
      api.getConfig({request: {
          userId: this.Viewform.id
        } }).then((res) => {
        this.questions = res.classList;
        this.questions.forEach((indicator) => {
          indicator.indicatorList.forEach((val) => {
            this.$set(this.answerList, val.indicatorId, {
              'indicatorId': val.indicatorId,
              'selectedOptionId': '',
              'remark': ''
            });
          });
        });
        this.getDetail();
      });
    },
    // 获取数据回显
    getDetail () {
      api.getAnswerInfo({request: {
          userId: this.Viewform.id
        } }).then((res) => {
        if (res.selfList.length != 0) {
          res.selfList.forEach((val) => {
            this.$set(this.answerList, val.indicatorId, {
              'indicatorId': val.indicatorId,
              'selectedOptionId': val.selectedOptionId,
              'remark': val.remark
            });
          });
        }
      });
    },
    // 跳转到评估审核页面
    handleGoAudit () {
      this.$router.push({
        name: 'peopelPostMatchAudit',
        query: {
          id: this.Viewform.id,
          userName: this.Viewform.userName,
          comId: this.Viewform.comId
        }
      });
    }
  },
  mounted () {
    this.Viewform.id = this.$route.query.id;
    this.Viewform.userName = this.$route.query.userName,
    this.Viewform.comId = this.$route.query.comId;
      this.getInfo();

  }
};
</script>

<style lang="scss">
.assessBox .app-container .qualityevaluation .Postproposal_input {
  margin-top: 20px;
}
.assessBox .app-container .qualityevaluation .evaluator div span {
  font-weight: bold;
}
// 修改左边原始评估样式
.container {
  background-color: #fff;
  .divider_line {
    width: 100%;
    height: 30px;
    background-color: #f6fbf7;
    margin-bottom: 20px;
  }
  .container_box {
    .el-radio__input.is-disabled + span.el-radio__label {
      color: #606266;
    }
  }
}
</style>
<style lang="scss">
.level_box {
  display: flex;
  // background-color: pink;
  .level_box_now {
    color: #4a8df0;
    font-weight: bold;
    font-size: 18px;
  }
  .level_box_old {
    color: #ccc;
    font-weight: bold;
    font-size: 18px;
    margin-left: 40px;
  }
  .level_box_btnbox {
    flex: 1;
    display: inline;
    text-align: right;
    padding-right: 30px;
    // background-color: blue;
    .level_box_btnbox_audit {
      width: 80px;
      // height: 40px;
    }
    .level_box_btnbox_export {
      width: 80px;
    }
  }
  .level_box_levelbox {
    flex: 1;
  }
}
.evaluator {
  text-align: left;
  font: bold 19px/28px 'Microsoft YaHei';
  padding: 20px 0px;
  padding-left: 54%;
  .evaluator_name {
    font-weight: bold;
    margin-right: 20px;
    min-width: 113px;
    display: inline-block;
  }
}
</style>

<style lang="scss" >
.assessBox {
  .app-container {
    color: #484848;
    padding: 0;
    background-color: #f6f7fb;
    .tw24 {
      display: block;
      // position: relative;
      // top: -24px;
    }
    > .el-button {
      float: left;
      color: #484848;
      transform: translateY(-8px);
      font-size: 16px;
    }
    .titlebox {
      text-align: center;
      line-height: 17px;
      background: #fff;
      font: bold 18px/25px 'Microsoft YaHei';
      margin-bottom: 20px;
      padding: 14px 40px;
      margin-bottom: 20px;
      > .el-button {
        float: left;
        color: #484848;
        transform: translateY(-8px);
        font-size: 16px;
      }
    }
    .viewbox {
      padding: 10px 30px;
      margin: 0 20px;
      background: #fff;
      .el-radio {
        margin-right: 20px;
      }
      > p {
        color: #4a8df0;
        font-weight: bold;
        font-size: 18px;
      }
      > div {
        margin-bottom: 14px;
        font: bold 14px/25px 'Microsoft YaHei';
        span {
          font-weight: normal;
        }
      }
      > div:nth-child(8) {
        margin-bottom: 0;
      }
      .title {
        font: bold 18px/25px 'Microsoft YaHei';
        color: #484848;
      }
      .ptitle {
        font: bold 14px/25px 'Microsoft YaHei';
        margin-bottom: 10px;
      }

      .el-textarea {
        width: 80%;
        // margin-left: 10px;
        vertical-align: middle;
        .el-textarea__inner {
          background-color: #f9fbff;
        }
      }
      > .el-textarea {
        width: 80%;
        margin-left: 0;
      }
      .radioArr {
        margin-left: 30px;
        margin-bottom: 10px;
        .el-radio {
          margin-right: 40px;
        }
      }
    }
    .qualityevaluation {
      // padding: 30px 0 10px 140px;
      background: #fff;
      margin: 0 20px 20px;
      div {
        font-weight: bold;
        span {
          font-weight: normal;
          font-size: 14px;
        }
      }
      .el-radio-button__inner,
      .el-radio-group {
        display: block;
      }
      .assessRadio {
        .radioArr {
          .el-radio {
            width: 100%;
            margin: 10px 0;
            white-space: pre-wrap;
            text-indent: -14px;
            margin-left: 28px;
          }
        }
      }
      .title {
        font: bold 18px/25px 'Microsoft YaHei';
        margin-bottom: 10px;
      }
      .ptitle {
        font: bold 14px/25px 'Microsoft YaHei';
        margin: 10px 0;
      }
      .el-textarea {
        width: 80%;
        margin: 5px 0px;
        vertical-align: middle;
        .el-textarea__inner {
          background-color: #f9fbff;
        }
      }
      > .el-textarea {
        width: 80%;
        margin-left: 0;
      }
      .radioArr {
        .el-radio {
          width: 100%;
          margin: 10px 0;
        }
      }
      .Talentstype {
        margin-top: 15px;
        .radioArr {
          width: 80%;
          margin-left: 10px;
          .el-radio {
            width: 10%;
            margin: 10px 5px;
          }
        }
      }
      .buttonBox {
        margin-top: 20px;
        padding: 0 25%;
        .el-button {
          width: 100px;
        }
      }
    }
    .Postproposal {
      margin-top: 15px;
      .el-textarea {
        margin-left: 0;
        display: block;
        width: 80%;
        .el-textarea__inner {
          background-color: #f9fbff;
        }
      }
      .radioArr {
        width: 80%;
        margin-left: 10px;
        .el-radio {
          //   width: 12%;
          margin: 10px 5px;
        }
      }
    }
  }
}
</style>


<style lang="scss">
.assessBox .app-container .viewbox .level_box_now {
  font-weight: bold;
}
.assessBox .app-container .viewbox .level_box_old {
  font-weight: bold;
}
</style>

